.container {
    background-image: url('../../../assets/img/background_invalid_param.png');
    background-size: 100% 100%;
    color: var(--color-primary);
    display: flex;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    flex-direction: column;
    height: calc(100vh - 20px);
    justify-content: center;
    padding: 8px;
    text-align: center;

    &_image {
        margin-top: 10px;

        .invert {
            filter: brightness(0) saturate(100%) invert(5%) sepia(30%) saturate(7159%) hue-rotate(231deg) brightness(99%) contrast(99%)
        }
    }

    &_image_bottom {
        padding-bottom: 20px;
    }

    h2 {
        color: var(--color-secondary);
        font-size: 30px;
        font-weight: 700;
        margin: 20px;
    }

    &_info {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin: 8px 28%;

        b {
            font-weight: 700;
        }
    }
}