.line {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 45%;

    span {
        text-align: left;
        width: 50%;
    }

    .desc {
        border-radius: 10px;
        text-align: center;
        padding: 8px;
        min-width: 200px;

        &_clasif {
            --brackgroundColor: #C1C1C1;
            --fontcolor: #525252;
            background: var(--brackgroundColor);
            color: var(--fontcolor);
            
            &_blue {
                background: var(--color-blue3);
                color: var(--color-white);
            }
        }

        &_risk {
            --brackgroundColor: #36B551;
            background: var(--brackgroundColor);
            color: var(--color-white);

            &_yellow {
                --backgroundColor: #FCD265;
                background: var(--backgroundColor);
            }

            &_red {
                --backgroundColor: #FF5151;
                background: var(--backgroundColor);
            }
        }
    }
}

.bar {
	display: flex;
	align-items: center;
	gap: 8px;

	.square {
		cursor: pointer;
		background: var(--color-steper-mobile);
		width: 40px;
		height: 27px;

		&_before {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		&_last {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.font_ligth {
		font-weight: 400;
	}

	&_line {
		display: flex;
        align-items: center;
        gap: 8px;
	}

	&_simple {
		--backgroundColor: #C1C1C1;
		height: 16px;
		width: 100%;
		border-radius: 20px;
		background-color: var(--backgroundColor);

		.progress {
			height: 16px;
			border-radius: 20px;
		}
	}
}